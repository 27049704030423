import React, { useEffect } from "react";
import NavbarSecond from "../common/navbarsecond/NavbarSecond";
import { HomeBlog, HomeTestimonial } from "../components";

const BlogsReviews = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
      <NavbarSecond />
      <HomeTestimonial />
      <HomeBlog />
    </>
  );
};

export default BlogsReviews;
