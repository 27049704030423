// import React from "react";
// import images from "../../constants/images";
// import "./ServiceHome.css";

// let service = {
//   service_first_desc:
//     "Each facial treatment is customized to meet your specific needs. I assess your skin type, concerns, and goals to create a personalized skincare  regimen that may include deep cleansing, exfoliation, extractions (if necessary), masks, serums, and moisturizers. The aim is to improve your skin's health, texture, and overall appearance.",
//   service_second_desc:
//     "In addition to facial treatments, I provide relaxing facial massages that help stimulate blood circulation, relieve tension, and promote a healthy glow. These massages can be incorporated into your facial session or booked as a standalone service.",
//   service_third_desc:
//     "If you're unsure about which facial treatment is right for you or need guidance on establishing an effective skincare routine, I offer personalized skincare consultations.Together, we'll discuss your concerns, evaluate your current skincare practices, and develop a tailored plan to achieve your desired skincare goals.",
// };

// const ServiceHome = () => {
//   return (
//     <div className="home-services-main">
//       {/* <div className="home-services-box"> */}
//       <div className="home-service-card">
//         <div className="home-service-face home-service-front">
//           <img
//             src={images.facial}
//             alt="facial"
//             className="home-services-icon"
//           />
//           <div className="home-service-card-frint-txt-box">
//             <div className="home-service-card-inner-txt-box">
//               <h3 className="home-service-card-title">Customized Facials</h3>
//               {/* <p className="home-services-card-txt">Starting from $50</p> */}
//             </div>
//           </div>
//         </div>
//         <div className="home-service-face home-service-back">
//           <p className="home-card-back-heading">Customized Facials</p>
//           <p>
//             <div className="box">{service.service_first_desc}</div>
//           </p>
//         </div>
//       </div>
//       {/* </div> */}

//       {/* <div className="home-services-box"> */}
//       <div className="home-service-card">
//         <div className="home-service-face home-service-front">
//           <img
//             src={images.face_massage}
//             alt="face"
//             className="home-services-icon"
//           />
//           <div className="home-service-card-frint-txt-box">
//             <div className="home-service-card-inner-txt-box">
//               <h3 className="home-service-card-title">Facial Massage</h3>
//               {/* <p className="home-services-card-txt">Starting from $50</p> */}
//             </div>
//           </div>
//         </div>
//         <div className="home-service-face home-service-back">
//           <p className="home-card-back-heading">Facial Massage</p>
//           <p>
//             <div className="box">{service.service_second_desc}</div>
//           </p>
//         </div>
//       </div>
//       {/* </div> */}
//       {/* <div className="home-services-box"> */}
//       <div className="home-service-card">
//         <div className="home-service-face home-service-front">
//           <img
//             src={images.consultation}
//             alt="consultation"
//             className="home-services-icon"
//           />
//           <div className="home-service-card-frint-txt-box">
//             <div className="home-service-card-inner-txt-box">
//               <h3 className="home-service-card-title">
//                 Skincare Consultations
//               </h3>
//               {/* <p className="home-services-card-txt">Starting from $50</p> */}
//             </div>
//           </div>
//         </div>
//         <div className="home-service-face home-service-back">
//           <p className="home-card-back-heading">Skincare Consultations</p>
//           <p>
//             <div className="box">{service.service_third_desc}</div>
//           </p>
//         </div>
//       </div>
//       {/* </div> */}
//     </div>
//   );
// };

// export default ServiceHome;

// import React, { useState } from "react";
// import images from "../../constants/images";
// import "./ServiceHome.css";

// let service = {
//   service_first_desc:
//     "Each facial treatment is customized to meet your specific needs. I assess your skin type, concerns, and goals to create a personalized skincare  regimen that may include deep cleansing, exfoliation, extractions (if necessary), masks, serums, and moisturizers. The aim is to improve your skin's health, texture, and overall appearance.",
//   service_second_desc:
//     "In addition to facial treatments, I provide relaxing facial massages that help stimulate blood circulation, relieve tension, and promote a healthy glow. These massages can be incorporated into your facial session or booked as a standalone service.",
//   service_third_desc:
//     "If you're unsure about which facial treatment is right for you or need guidance on establishing an effective skincare routine, I offer personalized skincare consultations.Together, we'll discuss your concerns, evaluate your current skincare practices, and develop a tailored plan to achieve your desired skincare goals.",
// };

// const ServiceHome = () => {
//   const [readMore, setReadMore] = useState(false);
//   const [readMore2, setReadMore2] = useState(false);
//   const [readMore3, setReadMore3] = useState(false);
//   return (
//     <div>
//       <div
//         className="home-services-box"
//         style={{
//           backgroundColor: "#f9f8f2",
//         }}
//       >
//         <div className="home-services-box-inner">
//           <img
//             src={images.facial}
//             className="home-services-icon"
//             // style={{ borderTopRightRadius: "150px" }}
//           />
//           <div style={{ textAlign: "left" }}>
//             <p className="home-services-box-heading">Customized Facials</p>
//             <p className="home-service-box-txt">
//               {service.service_first_desc === "" ||
//               service.service_first_desc == null ||
//               service.service_first_desc === undefined ? null : (
//                 <>
//                   {readMore
//                     ? service.service_first_desc
//                     : `${service.service_first_desc.substring(0, 100)}...`}

//                   <button
//                     className="btn-readmore"
//                     style={{
//                       background: "none",
//                       color: "#000",
//                       border: "none",
//                       cursor: "pointer",
//                       // marginTop: "10px",
//                       padding: "0px",
//                       fontWeight: "600",
//                     }}
//                     onClick={() => setReadMore(!readMore)}
//                   >
//                     {readMore ? "Show less" : "  Read more"}
//                   </button>
//                 </>
//               )}
//             </p>
//           </div>
//         </div>
//       </div>
//       <div
//         className="home-services-box"
//         style={{
//           backgroundColor: "#f7f2dc",
//         }}
//       >
//         <div className="home-services-box-inner">
//           <div style={{ textAlign: "left" }}>
//             <p className="home-services-box-heading">Facial Massage</p>
//             <p className="home-service-box-txt">
//               {service.service_second_desc === "" ||
//               service.service_second_desc == null ||
//               service.service_third_desc === undefined ? null : (
//                 <>
//                   {readMore2
//                     ? service.service_second_desc
//                     : `${service.service_second_desc.substring(0, 100)}...`}

//                   <button
//                     className="btn-readmore"
//                     style={{
//                       background: "none",
//                       color: "#000",
//                       border: "none",
//                       cursor: "pointer",
//                       // marginTop: "10px",
//                       padding: "0px",
//                       fontWeight: "600",
//                     }}
//                     onClick={() => setReadMore2(!readMore2)}
//                   >
//                     {readMore2 ? "Show less" : "  Read more"}
//                   </button>
//                 </>
//               )}
//             </p>
//           </div>
//           <img
//             src={images.face_massage}
//             className="home-services-icon"
//             // style={{ borderTopLeftRadius: "150px" }}
//           />
//         </div>
//       </div>
//       <div
//         className="home-services-box"
//         style={{
//           marginBottom: "150px",
//           backgroundColor: "#f9f8f2",
//         }}
//       >
//         <div className="home-services-box-inner">
//           <img
//             src={images.consultation}
//             className="home-services-icon"
//             // style={{ borderTopRightRadius: "150px" }}
//           />
//           <div style={{ textAlign: "left" }}>
//             <p className="home-services-box-heading">Skincare Consultations</p>
//             <p className="home-service-box-txt">
//               {service.service_third_desc === "" ||
//               service.service_third_desc == null ||
//               service.service_third_desc === undefined ? null : (
//                 <>
//                   {readMore3
//                     ? service.service_third_desc
//                     : `${service.service_third_desc.substring(0, 100)}...`}

//                   <button
//                     className="btn-readmore"
//                     style={{
//                       background: "none",
//                       color: "#000",
//                       border: "none",
//                       cursor: "pointer",
//                       // marginTop: "10px",
//                       padding: "0px",
//                       fontWeight: "600",
//                     }}
//                     onClick={() => setReadMore3(!readMore3)}
//                   >
//                     {readMore3 ? "Show less" : "  Read more"}
//                   </button>
//                 </>
//               )}
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ServiceHome;

import React from "react";
import images from "../../constants/images";
import "./ServiceHome.css";

const service = {
  service_first_desc:
    "Each facial treatment is customized to meet your specific needs. I assess your skin type, concerns, and goals to create a personalized skincare regimen that may include deep cleansing, exfoliation, extractions (if necessary), masks, serums, and moisturizers. The aim is to improve your skin's health, texture, and overall appearance.",
  service_second_desc:
    "In addition to facial treatments, I provide relaxing facial massages that help stimulate blood circulation, relieve tension, and promote a healthy glow. These massages can be incorporated into your facial session or booked as a standalone service.",
  service_third_desc:
    "If you're unsure about which facial treatment is right for you or need guidance on establishing an effective skincare routine, I offer personalized skincare consultations. Together, we'll discuss your concerns, evaluate your current skincare practices, and develop a tailored plan to achieve your desired skincare goals.",
};

const ServiceHome = () => {
  return (
    <div>
      <div
        className="home-services-box"
        style={{
          backgroundColor: "#f9f8f2",
        }}
      >
        <div className="home-services-box-inner">
          <img src={images.facial} className="home-services-icon" />
          <div style={{ textAlign: "left" }}>
            <p className="home-services-box-heading">Customized Facials</p>
            <p className="home-service-box-txt">{service.service_first_desc}</p>
          </div>
        </div>
      </div>
      <div
        className="home-services-box"
        style={{
          backgroundColor: "#f8f4e1",
        }}
      >
        <div className="home-services-box-inner">
          <div style={{ textAlign: "left" }}>
            <p className="home-services-box-heading">Facial Massage</p>
            <p className="home-service-box-txt">
              {service.service_second_desc}
            </p>
          </div>
          <img src={images.face_massage} className="home-services-icon" />
        </div>
      </div>
      <div
        className="home-services-box"
        style={{
          marginBottom: "150px",
          backgroundColor: "#f9f8f2",
        }}
      >
        <div className="home-services-box-inner">
          <img src={images.consultation} className="home-services-icon" />
          <div style={{ textAlign: "left" }}>
            <p className="home-services-box-heading">Skincare Consultations</p>
            <p className="home-service-box-txt">{service.service_third_desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHome;
