import React, { useEffect } from 'react';
import './Consultation.css'; // Include your CSS file for styling
import NavbarSecond from '../../common/navbarsecond/NavbarSecond';
import { Link } from 'react-router-dom';
import 'animate.css';


const Consultation = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <>
        <NavbarSecond/>
        <div className="consultation-container">
            <section className="intro-section">
                <h1 className='head_text_cons'>Why Do You Need a Consultation?</h1>
                <p className='text_cons'>
                    We understand that skincare can be difficult sometimes, especially with the sea of products available in the market. 
                    It’s easy to feel overwhelmed when trying to find what’s best for your unique skin type. Your skin is a vital organ, 
                    and just like any other part of your body, it deserves specialized care and attention, and that’s where we come in.
                </p>
                <p className='text_cons'>
                    With our extensive expertise and personalized approach, we’re here to help you navigate in the right direction and ensure 
                    your skin gets the care it truly deserves.
                </p>
            </section>

            <section className="benefits-section">
                <h2 className='head_text_cons'>How Will the Consultation Help You?</h2>
                <ul className='cons_lists'>
                    <li className='cons_list'><strong className='cons_list_bold'>Personalized Guidance:</strong> We’ll assess your skin’s specific needs and recommend products and routines tailored just for you.</li>
                    <li className='cons_list'><strong className='cons_list_bold'>Targeted Solutions:</strong> Whether you’re dealing with dryness, breakouts, aging, or sensitivity, we’ll provide targeted solutions for your concerns.</li>
                    <li className='cons_list'><strong className='cons_list_bold'>Product Insights:</strong> Gain a deeper understanding of how different ingredients and products can benefit your skin and how to use them effectively.</li>
                    <li className='cons_list'><strong className='cons_list_bold'>Routine Optimization:</strong> We’ll streamline your skincare routine, making it more effective and manageable.</li>
                    <li className='cons_list'><strong className='cons_list_bold'>Expert Support:</strong> Benefit from our knowledge and experience, ensuring you make informed choices for your skincare journey.</li>
                </ul>
            </section>

            <section className="consultant-section">
                <h2 className='head_text_cons'>Who Will Be Taking Your Consultation?</h2>
                <p className='text_cons'>
                    <strong>Ishitta</strong>, founder of Ish Essence and Renitae, and a licensed cosmetologist specializing in skincare, will be leading this consultation. 
                    Ishitta has years of experience in the skincare industry, and as an expert in this field, she combines her extensive knowledge and hands-on expertise 
                    to provide personalized skincare solutions. Her passion for skincare and dedication to helping others ensures that you'll receive the most insightful 
                    and effective guidance tailored to your unique needs.
                </p>
            </section>

            <section className="call-to-action cons_last_sec">
                <h2 className='head_text_cons'>Ready to Get Started?</h2>
                <p className='text_cons'>
                    If you’re ready to simplify your skincare routine and achieve the glowing, healthy skin you’ve always wanted, book a consultation with us today. 
                    We’re excited to guide you on your journey to radiant skin.
                </p>
            </section>
            <Link to='https://calendly.com/ishessence/30min' target='_blank' className="book-consultation-btn">Book Consultation</Link>

        </div>
        </>
    );
};

export default Consultation;
