import React from "react";
import "./HomeServices.css";
import ServiceHome from "../servicehome/ServiceHome";

const HomeServices = () => {
  return (
    <>
      <div className="home-services-sec">
        <div className="home-services-flex container">
          <div className="home-services-heading">
            {/* <div className="main-nav-logo">
            <Link to="/">
              <img
                src={images.logo_2}
                className="nav-logo nav-logoo"
                alt="logo"
              />
            </Link>
          </div> */}
            {/* <img
            src={images.leaf_head_img}
            className="home-services-heading-img"
          /> */}
            <p className="home-services-head-txt">ENJOY SOOTHING EXPERIENCE</p>
            <p className="home-services-head-big-txt">
              Welcome to my Skin Studio
            </p>
            <p className="home-services-head-txt2">
              At Ish Essence, I believe that healthy and beautiful skin starts
              with personalized care. As a single-owned studio, I am dedicated
              to providing exceptional skincare services tailored to your unique
              needs and preferences. With experience in the industry and a
              passion for helping clients achieve their skincare goals, I am
              committed to delivering outstanding results in a warm and inviting
              atmosphere.
            </p>
          </div>
        </div>
      </div>
      <ServiceHome />
    </>
  );
};

export default HomeServices;
