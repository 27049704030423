import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import images from "../constants/images";
import ReactModal from "react-modal";

import {
  Hero,
  HomeBlog,
  HomeBrandsLogo,
  HomeFacility,
  HomePackages,
  HomeReversableCard,
  HomeServices,
  HomeTestimonial,
  HomeVideo,
} from "../components";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Navbar } from "../common";
import { Helmet } from "react-helmet";
import { IoClose } from "react-icons/io5";
import OurTeam from "../components/ourteam/OurTeam";
import { Link } from "react-router-dom";




const Landingpage = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(()=>{
    window.scrollTo(0, 0);
},[])


  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 420);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 420);
    };
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)", // Final position
      padding: "0px",
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "0px",
      maxHeight: "670px",
      minHeight: "250px",
      maxWidth: "95%",
      width:"550px",
      background: "transparent",
      animation: "slideDown 0.5s ease-out", // Adding the animation here
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };


  const [modalIsOpenForgot, setIsOpenForgot] = useState(false);


  function closeModalFogot() {
    setIsOpenForgot(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpenForgot(true);
    }, 5000);

    // Clean up the timeout if the component unmounts or if dependencies change
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>ISH ESSENCE | Organic Skin Studio in Bowling Green</title>
      </Helmet>
      {/* <Navbar /> */}

      <Hero />
      <HomeServices />
      <HomeReversableCard />
      <OurTeam />
      {/* <HomeFacility /> */}
      {/* <HomeVideo /> */}
      {/* <HomeTestimonial /> */}
      <HomeBrandsLogo />
      {/* <HomeBlog /> */}
      {/* <HomePackages /> */}
      {/* <div className="whats-app-main">
                <button className="whats-app" onClick={() => window.scrollTo(0, 0)}>
                    <AiOutlineArrowUp className="float-icon" />
                </button>
            </div> */}
        <div className="animate__animated animate__bounce">
    <ReactModal
  isOpen={modalIsOpenForgot}
  onRequestClose={closeModalFogot}
  style={customStyles}
>
  <div
    className="model_sizing"
    style={{
      padding: "4rem",
      backgroundImage: `url(${"https://thumbs.dreamstime.com/b/generated-ai-image-illustration-harmony-wrinkle-serum-leisure-hydration-mask-cream-jar-anti-aging-flower-extract-face-rejuvention-307341544.jpg"})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    }}
  >
    <div className="modal_bg_color"></div>
    <div>
      <button
        className="signup_modal_close"
        style={{ right: "7px", top: "9px" }}
        onClick={closeModalFogot}
      >
        <IoClose size={20} color="#fff" />
      </button>
    </div>

    <div
      className="home_login_model_1sec_inner"
      style={{
        maxWidth: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255,255,255, 0.3)",
        padding: "2rem",
        borderRadius: "8px",
      }}
    >
      <div className="landing_modal_line">
        One step closer to glowing skin! Book a consultation!
      </div>

      <Link
        to="/Consultation"
        className="consultation-btn"
        style={{ cursor: "pointer", zIndex: "99" }}
      >
        Book Consultation
      </Link>
    </div>
  </div>
</ReactModal>
      </div>
    </>
  );
};

export default Landingpage;
