import "./App.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Landingpage from "./pages/Landingpage";
import Navbar from "./common/Navbar/Navbar";
import Footer from "./common/Footer/Footer";
import OurMenu from "./pages/OurMenu";
import { AiOutlineArrowUp } from "react-icons/ai";
import About from "./pages/About";
import Contact from "./pages/Contact";
import BlogsReviews from "./pages/BlogsReviews";
import Services from "./pages/Services";
import Consultation from "./pages/consultation/Consultation";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/ourmenu" element={<OurMenu />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs-reviews" element={<BlogsReviews />} />
        <Route path="/Consultation" element={<Consultation />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <Link
        // to="https://na2.meevo.com/CustomerPortal/onlinebooking/booking/guestinfo?tenantId=501474&locationId=501886"
        to="https://ishessence.glossgenius.com/"
        target="_blank"
        className="subscription-btn"
      >
        Book Appointment
      </Link>
      <div className="whats-app-main">
        <button className="whats-app" onClick={() => window.scrollTo(0, 0)}>
          <AiOutlineArrowUp className="float-icon" />
        </button>
      </div>
    </BrowserRouter>
  );
}

export default App;
