import React from "react";
import images from "../../constants/images";
import "./Menu.css";

const Menu = () => {
  return (
    <div className="menu-main">
      <div className="menu">
        <img src={images.bg3} className="menu-hero" />
      </div>
    </div>
  );
};

export default Menu;
