import React from "react";
import { useEffect } from "react";
import NavbarSecond from "../common/navbarsecond/NavbarSecond";
import ContactInfo from "../components/contactinfo/ContactInfo";
import { HomeVideo } from "../components";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavbarSecond />
      <HomeVideo />
      <ContactInfo />
    </div>
  );
};

export default Contact;
