import React, { useEffect } from "react";
import images from "../constants/images";
import NavbarSecond from "../common/navbarsecond/NavbarSecond";
import { HomeFacility } from "../components";

const Services = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
      <NavbarSecond />
      <HomeFacility />
    </>
  );
};

export default Services;
